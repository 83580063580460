<template>
  <div>
    <!-- 领取优惠券 -->

    <!-- 优惠券领完了 -->
    <el-row>
      <p class="text-c">来晚了,优惠券领完了~</p>
      <el-image :src="couponLw"></el-image>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      couponLw: require("@/assets/img/coupon/Coupon-lw.png"),  // 优惠券领完图片
    };
  },
};
</script>

<style lang="less" scoped>
</style>